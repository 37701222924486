import { Grid, Typography } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { IPreguntasContent } from "../../../pages/preguntas-frecuentes"
import { Accordion, RichTextRenderer } from "../../_common"
import { SectionContainer, SectionText, SectionTitle } from "../../_layout"

import useStyles from "./styles"

interface IFAQ {
  preguntas: IPreguntasContent[]
}

const FAQ: FC<IFAQ> = ({ preguntas }) => {
  const styles = useStyles()

  return (
    <SectionContainer sectionID="preguntas-frecuentes">
      <SectionTitle title="Preguntas frecuentes" />
      <SectionText text={'Da click en cada pregunta para ver la respuesta'} />

      { preguntas.map((e: IPreguntasContent, index: number) => (
        <Grid key={index} className={ styles.qa }>
          <Accordion light title={ e.titulo } content={ <RichTextRenderer content={ e.contenido } /> } />
        </Grid>
      )) }
    </SectionContainer>
  )
}

export default FAQ
