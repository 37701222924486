import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Layout } from "../components/_layout"
import { Contact } from "../components/ContactModule"
import FAQ from "../components/PreguntasFrecuentesSection/FAQ/FAQ"

const getPageData = graphql`
  query {
    preguntasData: allContentfulContenidoPreguntasFrecuentes(
        sort: { fields: [orden], order: ASC }
    ) {
      edges {
        node {
          id
          titulo
          contenido {
            raw
          }
        }
      }
    }
  }
`

export interface IPreguntasContent {
  id: string
  titulo: string
  contenido: string
}

const PNIF: React.FC = () => {
  const { preguntasData } = useStaticQuery(getPageData)
  const preguntas: IPreguntasContent[] = getSectionData(preguntasData)

  return (
    <Layout
      withNav
      seoTitle="PNIF"
      seoDescription="Objetivos - logros - alianzas"
    >
      <FAQ {...{ preguntas }} />
      <Contact />
    </Layout>
  )
}

export default PNIF

const getSectionData = (arr: any) =>
  arr.edges.map(({ node }: any) => ({
    id: node.id,
    titulo: node.titulo,
    contenido: node.contenido.raw,
  }))
