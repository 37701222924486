import {
  FormSchemaTextObject,
  FormSchemaValidation,
} from "../../../helpers/Types"

import { validationStore } from "../../../helpers/helperFunctions"

interface IContactFormType<T> {
  nombre: T
  email: T
  mensaje: T
}

interface Schema {
  initialState: IContactFormType<FormSchemaTextObject>
  validationStateSchema: IContactFormType<FormSchemaValidation>
}

export default (): Schema => ({
  initialState: {
    nombre: { value: "", error: "" },
    email: { value: "", error: "" },
    mensaje: { value: "", error: "" },
  },
  validationStateSchema: {
    nombre: { required: true },
    email: {
      required: true,
      validator: {
        regEx: validationStore.email,
        error: "Favor de introducir un correo válido.",
      },
    },
    mensaje: { required: true },
  },
})
