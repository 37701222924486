import axios from "axios"
import * as React from "react"
import { useState } from "react"
import { SectionContainer, SectionTitle } from "../../_layout"
import ContactForm from "../ContactForm/ContactForm"

interface IContact {
}

interface IContactFormData {
  nombre: string
  email: string
  mensaje: string
}

const FORMSPREE_URL: string = "https://formspree.io/f/mgepbaen"

const Contact: React.FC<IContact> = () => {
  const [contactSuccess, setContactSuccess] = useState(null)

  const handleSendToFormSpree = async (data: IContactFormData) => {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    try {
      const res = await axios.post(FORMSPREE_URL, formData)
      if (res.status === 200) {
        setContactSuccess(true)
      }
    } catch (err) {
      setContactSuccess(false)
    }
  }

  return (
    <SectionContainer sectionID="contact">
      <SectionTitle centered title="Si tienes alguna duda contáctanos" />
      <ContactForm
        onFormSubmit={ handleSendToFormSpree }
        stateOptions={ {
          state: contactSuccess,
          goBackAction: () => setContactSuccess(null),
          submitSuccessMessage: "Nos pondremos en contacto muy pronto.",
          submitErrorMessage:
            "Error al enviar la forma de contacto. Revisa que la información sea correcta."
        } }
      />
    </SectionContainer>
  )
}

export default Contact
