import * as React from "react"
import { FC } from "react"

import { FormSchemaTextObject, TAnyCallback } from "../../../helpers/Types"
import { useFormUpdate } from "../../../hooks"

import { FormContainer, FormTextInput } from "../../_forms"
import { IFormStateOptions } from "../../_forms/FormContainer/FormContainer"
import useSchemas from "./form.schema"

export interface ContactFormSchemaData {
  nombre: FormSchemaTextObject
  email: FormSchemaTextObject
  mensaje: FormSchemaTextObject
}

interface ContactFormProps {
  onFormSubmit: TAnyCallback
  stateOptions?: IFormStateOptions
}

const ContactForm: FC<ContactFormProps> = ({ onFormSubmit, stateOptions }) => {
  const { initialState, validationStateSchema } = useSchemas()

  const {
    updatedData,
    handleUpdateData,
    handleOnSubmit,
    buttonState,
    isButtonLoading
  } = useFormUpdate<ContactFormSchemaData>(
    initialState,
    validationStateSchema,
    async cleanData => {
      await onFormSubmit(cleanData)
      updatedData.nombre.value = ''
      updatedData.email.value = ''
      updatedData.mensaje.value = ''
    }
  )

  return (
    <FormContainer
      centeredForm
      handleSubmit={ handleOnSubmit }
      buttonState={ buttonState }
      isButtonLoading={ isButtonLoading }
      buttonLabelText="Enviar"
      { ...{ stateOptions } }
    >
      <FormTextInput
        required
        name="nombre"
        label="Nombre"
        value={ updatedData.nombre.value }
        errorValue={ updatedData.nombre.error }
        onChange={ handleUpdateData }
      />
      <FormTextInput
        required
        name="email"
        label="Email"
        value={ updatedData.email.value }
        errorValue={ updatedData.email.error }
        onChange={ handleUpdateData }
      />
      <FormTextInput
        required
        textArea
        name="mensaje"
        label="Mensaje"
        value={ updatedData.mensaje.value }
        errorValue={ updatedData.mensaje.error }
        onChange={ handleUpdateData }
      />
    </FormContainer>
  )
}

export default ContactForm
